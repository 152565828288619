import React, { useEffect } from "react"
import Styles from "./SaveNewPart.module.scss"

const CompatibilityInput = ({
  clearCompInputs,
  handleDropDownChange,
  compCarInputs,
  setCompCarInputs,
  saveInfo,
  changeInfo,
  models,
  setModels,
  getModels,
  makes,
}) => {
  useEffect(() => {
    if (compCarInputs.make){
    getAndSetModels(compCarInputs.make)
    }
  }, [compCarInputs.make])

  async function getAndSetModels(make) {
    let models = await getModels(make)
    setModels([...models, "Enter Manually"])
  }
  const makeOptions = () => {
    let options = makes.map((make) => {
      return (
        <option key={make} id={make} value={make}>
          {make}
        </option>
      )
    })

    return options
  }

  const modelOptions = () => {
    let options = models.map((model) => {
      return (
        <option key={model} id={model} value={model}>
          {model}
        </option>
      )
    })

    return options
  }

  const yearOptions = () => {
    let numbers = []
    for (let i = new Date().getFullYear(); i > 1964; i--) {
      numbers.push(i)
    }
    let options = numbers.map((number) => {
      return (
        <option key={number} id={number} value={number}>
          {number}
        </option>
      )
    })

    return options
  }

  const handleCompInput = (e) => {
    const { value, id } = e.target
    if (id === "yearFrom" && !compCarInputs.yearTo) {
      setCompCarInputs({ ...compCarInputs, yearFrom: value, yearTo: value })
    } else {
      setCompCarInputs({ ...compCarInputs, [id]: value })
    }
  }
  const handleMakeChange = async (e) => {
    const { value, id } = e.target
    let models = await getModels(value)
    setModels([...models, "Enter Manually"])
    setCompCarInputs({ ...compCarInputs, make: value })
  }

  const handleConfirm = () => {
    const { yearFrom, yearTo, make, model, id } = compCarInputs
    if (compCarInputs.editMode) {
      let foundIndex = saveInfo.compatibleCars.findIndex((car) => car.id === id)
      let arrayToEdit = saveInfo.compatibleCars
      arrayToEdit[foundIndex] = { yearFrom, yearTo, make, model, id }
      changeInfo({ ...saveInfo, compatibleCars: arrayToEdit })
      clearCompInputs()
    } else if (!(yearFrom && yearTo && make && model)) {
      alert("You cannot have any empty fields")
    } else {
      let foundDuplicate = saveInfo.compatibleCars.find(
        (x) =>
          x.yearTo === yearTo &&
          x.yearFrom === yearFrom &&
          x.make === make &&
          x.model === model
      )
      if (!foundDuplicate) {
        changeInfo({
          ...saveInfo,
          compatibleCars: [
            ...saveInfo.compatibleCars,
            {
              yearFrom,
              yearTo,
              make,
              model,
              id: `${yearFrom}${yearTo}${make}${model}`,
            },
          ],
        })
      }
      clearCompInputs()
    }
  }

  const handleCancel = () => {
    clearCompInputs()
  }

  return (
    <div className={Styles["addCarInputs"]} style={{ display: "flex" }}>
      <img
        className={Styles["addOrDeleteImg"]}
        onClick={handleCancel}
        src={require("../../../images/cancel.png")}
      />
      <select
        className={Styles["yearDropdown"]}
        onChange={handleCompInput}
        name="yearFrom"
        id="yearFrom"
        value={compCarInputs.yearFrom}
      >
        <option key="yearFrom" value="" disabled>
          Year
        </option>
        {yearOptions()}
      </select>
      <span>-</span>
      <select
        className={Styles["yearDropdown"]}
        onChange={handleCompInput}
        name="yearTo"
        id="yearTo"
        value={compCarInputs.yearTo}
      >
        <option key="yearTo" value="" disabled>
          Year
        </option>
        {yearOptions()}
      </select>
      <select
        className={Styles["dropdown"]}
        onChange={handleMakeChange}
        name="make"
        id="make"
        value={compCarInputs.make}
      >
        <option key="make" value="" disabled>
          Makes
        </option>
        {makeOptions()}
      </select>

      {compCarInputs.isManualEntry ? (
        <input
          className={Styles["dropdown"]}
          type="text"
          id="model"
          value={compCarInputs.model}
          onChange={(e) => handleCompInput(e)}
          placeholder="Model"
          autoFocus
        />
      ) : (
        <select
          className={Styles["dropdown"]}
          onChange={handleDropDownChange}
          name="model"
          id="model"
          value={compCarInputs.model}
        >
          <option key="model" value="" disabled>
            Models
          </option>
          {modelOptions()}
        </select>
      )}

      <div className={Styles["confirmDelete"]}>
        <img
          className={Styles["addOrDeleteImg"]}
          onClick={handleConfirm}
          src={require("../../../images/confirm.png")}
        />
      </div>
    </div>
  )
}

export default CompatibilityInput

import React from "react";
import {Link} from "react-router-dom"
import Styles from "./NavBar.module.scss";

const NavBar = (props) => {

    return (
        <nav className={Styles.wrapper}>
            <Link to="/research">Research</Link>
            <Link to="/quicksearch">QuickSearch</Link>
            <Link to="/sellersearch">SellerSearch</Link>
            <Link to="/yardsearch">YardSearch</Link>
            <Link to="/hotlist">Hot List</Link>
            <Link to="/priceList">Price List</Link>
       </nav>
    );
}

export default NavBar;
import extractCompatibleVehicles from "./extractCompatibleVehicles"
import toProperCase from "../../../../helpers/toProperCase"
String.prototype.toProperCase = toProperCase

async function buildSavePartDetails(listingInfo, makes, getModels) {
    const {price, shipping, imgurl, _liters: notes, _normalized: title} = listingInfo
  
  
    const savePartDetails = {
    imgurl,
    title: title.toProperCase(),
    salesPer: 0,
    price: Math.ceil(price + shipping),
    notes: notes || "",
    compatibleCars: await extractCompatibleVehicles(makes, getModels, listingInfo),
  }

  return savePartDetails
}

export default buildSavePartDetails
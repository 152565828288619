import React from "react"
import Styles from "./ListingTable.module.scss"
import ListingGroup from "./ListingGroup"

const ListingTable = ({
  listingsToShow = [],
  makes,
  getModels,
  setSavePartDetails,
}) => {
  const listingGroupElements = (<div className={Styles.listingGroupsWrapper}>
  {listingsToShow.map(group => (
    <ListingGroup key={group[0].title + group.length} {...{ setSavePartDetails, group, makes, getModels }} />
  ))}
</div>)
  
  return (
    <div className={Styles["listingTableWrapper"]}>{listingGroupElements}</div>
  )
}

export default ListingTable

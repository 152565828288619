import React, { useState, useEffect, useContext } from "react"
import Styles from "./SellerSearch.module.scss"
import SearchModal from "./SearchModal/SearchModal"
import SellerDisplay from "./SellerDisplay/SellerDisplay"
import ListingTable from "./ListingTable/ListingTable"
import SaveNewPart from "./SaveNewPart/SaveNewPart"
import { applyFiltersToListings } from "./sellerSearchUtils"
import { storeContext } from "../../Store"

const SellerSearch = ({ sellerListingsAndData, getSellerListingsAndInfo }) => {
  const storeData = useContext(storeContext)
  const { addPartToTicket, makes, getModels, getPartTitlesUsingCode } =
    storeData
  const [loading, setLoading] = useState(false)
  const { listingGroups, sellerInfo } = sellerListingsAndData || {}
  const [filters, setFilters] = useState(
    localStorage.getItem("sellerFilters")
      ? JSON.parse(localStorage.getItem("sellerFilters"))
      : {
          minPrice: 1,
          maxPrice: 1000,
          minQuantity: 1,
          saleAgeLimit: 90,
          keywordsToIgnore: "",
          sortBy: "countThenPrice",
          keyword: "",
          username: "",
        }
  )
  const listingsToShow = applyFiltersToListings(listingGroups, filters)

  const [savePartDetails, setSavePartDetails] = useState(null)

  useEffect(() => {
    if (sellerInfo.storeName) {
      setLoading(false)
    }
    if(loading && !sellerInfo.storeName) {
      alert("Seller not found.")
    }

  }, [listingGroups])

  const getSellerInfo = (username) => {
    setLoading(true)
    getSellerListingsAndInfo(username)
  }

  const applyFilters = (filters) => {
    localStorage.setItem("sellerFilters", JSON.stringify(filters))
    setFilters(filters)
  }

  const savePart = (info) => {
    addPartToTicket(info)
    setSavePartDetails(null)
  }

  const searchLocation = (year, make, model, title) => {
    let url = `https://www.google.com/search?q=${year}+${make}+${model.replace(
      /\s/g,
      "+"
    )}+${title.replace(/\s/g, "+")}+location`
    window.open(url, "_blank")
  }
  const searchCompatibility = (year, make, model, title) => {
    const url = `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${year}+${make}+${model}+${title}&_sacat=6028&LH_TitleDesc=0&LH_Complete=1&LH_Sold=1&_sop=10&rt=nc&LH_ItemCondition=4&_ipg=200&_udlo=50`
    window.open(url, "_blank")
  }

  return (
    <div className={Styles["sellerSearchWrapper"]}>
      {savePartDetails && (
        <SaveNewPart
          {...{
            savePartDetails,
            setSavePartDetails,
            savePart,
            makes,
            getModels,
            searchLocation,
            searchCompatibility,
          }}
        />
      )}
      <div>
        <SearchModal
          getSellerInfo={getSellerInfo}
          loading={loading}
          applyFilters={applyFilters}
          filters={filters}
        />
        <SellerDisplay sellerInfo={sellerInfo} />
      </div>
      <ListingTable
        {...{ listingsToShow, setSavePartDetails, makes, getModels }}
      />
    </div>
  )
}

export default SellerSearch

import React, { useContext } from "react"
import SignIn from "./components/SignIn/SignIn"
import Research from "./components/Research/Research"
import Header from "./components/Header/Header"
import HotList from "./components/HotList/HotList"
import PullForm from "./components/PullForm/PullForm"
import { Switch, Route, Redirect } from "react-router-dom"
import ProtectedRoute from "./ProtectedRoute"
import { storeContext } from "./Store"
import PriceList from "./components/PriceList/PriceList"
import YardSearch from "./components/YardSearch/YardSearch"
import AutoResearch from "./components/AutoResearch/AutoResearch"
import QuickSearch from "./components/QuickSearch/QuickSearch"
import SellerSearch from "./components/SellerSearch/SellerSearch"

function App() {
  const storeData = useContext(storeContext)
  const {
    getCars,
    cars,
    showPullForm,
    pullTickets,
    hotList,
    user,
    getAllResearchParts,
    setAllResearchParts,
    allResearchParts,
    populatePartsAndPulls,
    editPartFromTicket,
    deletePartFromTicket,
    updateManyCars,
    getSellerListingsAndInfo,
    sellerListingsAndData,
  } = storeData
  return (
    <div className="app-wrapper">
      <Header />
      <Switch>
        <Route path="/auth/signin" component={SignIn} />
        <Route
          exact
          path="/"
          component={() => <Redirect to="/auth/signin" />}
        />
        <ProtectedRoute
          exact
          path="/research"
          component={() => <Research getCars={getCars} />}
        />
        <ProtectedRoute
          exact
          path="/quicksearch"
          component={() => (
            <QuickSearch
              allResearchParts={allResearchParts}
              getAllResearchParts={getAllResearchParts}
              setAllResearchParts={setAllResearchParts}
            />
          )}
        />
        <ProtectedRoute
          exact
          path="/sellersearch"
          component={() => (
            <SellerSearch
              getSellerListingsAndInfo={getSellerListingsAndInfo}
              sellerListingsAndData={sellerListingsAndData}
            />
          )}
        />
        {/* <ProtectedRoute path="/research/auto" component={() => <AutoResearch autoSearchDays={user.daysAutoSearch} pullTickets={pullTickets} hotList={hotList} cars={cars}/>} /> */}
        <ProtectedRoute
          path="/yardsearch"
          component={() => (
            <YardSearch
              updateManyCars={updateManyCars}
              deletePartFromTicket={deletePartFromTicket}
              editPartFromTicket={editPartFromTicket}
              populatePartsAndPulls={populatePartsAndPulls}
              cars={cars}
            />
          )}
        />
        <ProtectedRoute
          path="/hotlist"
          component={() => <HotList getCars={getCars} cars={cars} />}
        />
        <ProtectedRoute path="/pricelist" component={() => <PriceList />} />
      </Switch>
      {showPullForm && <PullForm />}
    </div>
  )
}

export default App

import React, { useEffect, useState, useContext } from "react"
import Styles from "./QuickSearch.module.scss"
import SearchModal from "./SearchModal/SearchModal"
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import SavePartModal from "./SavePartModal/SavePartModal"
import SaveNewPart from "./SaveNewPart/SaveNewPart"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import { storeContext } from "../../Store"

const QuickSearch = () => {
  const storeData = useContext(storeContext)
  const {
    getAllResearchParts,
    allResearchParts,
    setAllResearchParts,
    addPartToTicket,
    makes,
    getModels,
    getPartTitlesUsingCode,
  } = storeData
  const data = allResearchParts || [] // Default to an empty array if there's no data.
  const [saveNewPartModal, setSaveNewPartModal] = useState(null)
  const [savePartDetails, setSavePartDetails] = useState(null)

  const searchLocation = (year, make, model, title) => {
    let url = `https://www.google.com/search?q=${year}+${make}+${model.replace(
      /\s/g,
      "+"
    )}+${title.replace(/\s/g, "+")}+location`
    window.open(url, "_blank")
  }
  const searchCompatibility = (year, make, model, title) => {
    const url = `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${year}+${make}+${model}+${title}&_sacat=6028&LH_TitleDesc=0&LH_Complete=1&LH_Sold=1&_sop=10&rt=nc&LH_ItemCondition=4&_ipg=200&_udlo=50`
    window.open(url, "_blank")
  }
  const savePart = (info) => {
    addPartToTicket(info)
    setSavePartDetails(null)
  }

  const markItemAsSeen = (e) => {
    // Use e.currentTarget to ensure you're getting the element with the event listener
    const id = e.currentTarget.id;
    if (id) {
      const item = document.getElementById(id);
      if (item) {
        item.style.background = "#e3f385";
      }
    }
  }
  const content = !allResearchParts ? (
    <SearchModal getAllResearchParts={getAllResearchParts} />
  ) : (
    <div className={Styles.quickSearchWrapper}>
      <button
        className={Styles.newPartButton}
        onClick={() => setSaveNewPartModal({})}
      >
        Save Part
      </button>
      <button
        className={Styles.newSearchButton}
        onClick={() => setAllResearchParts(null)}
      >
        New Search
      </button>
      <div className={Styles.tableWrapper}>
        <table className={Styles.scrollableTable}>
          <thead>
            <tr>
              <th>Car</th>
              <th>Part Title</th>
              <th style={{ textAlign: "center" }}>Quantity</th>
              <th>Average Price</th>
              <th>Highest Price</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const url = `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${item.car}%20${item.partTitle}&_sacat=6028&LH_TitleDesc=0&LH_Complete=1&LH_Sold=1&_sop=10&rt=nc&LH_ItemCondition=4&_ipg=200&_udlo=50`
              return (
                <tr onClick = {(e)=> markItemAsSeen(e)} id={index} key={index}>
                  <td style={{}} className={Styles.carLinkWrapper}>
                    {item.car}

                    <a target="_blank" href={url}>
                      <ArrowOutwardIcon />
                    </a>
                    <div className="spacer"></div>
                    <SettingsApplicationsIcon
                      onClick={async () => {
                        const titles = await getPartTitlesUsingCode(item.code)
                        alert(titles)
                      }}
                    />
                    <AddCircleIcon
                      onClick={() =>
                        setSaveNewPartModal({
                          carYear: item.year,
                          carMake: item.make,
                          carModel: item.model,
                          salesPer: item.quantity,
                          price: item.averagePrice,
                          title: item.partTitle,
                          image: item.imgurl,
                        })
                      }
                    />
                  </td>
                  <td>{item.partTitle}</td>
                  <td style={{ textAlign: "center" }}>{item.quantity}</td>
                  <td>${item.averagePrice}</td>
                  <td>${item.highestPrice}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {saveNewPartModal && (
        <SavePartModal
          setSavePartDetails={setSavePartDetails}
          setSaveNewPartModal={setSaveNewPartModal}
          {...saveNewPartModal}
        />
      )}
      {savePartDetails && (
        <SaveNewPart
          {...savePartDetails}
          savePart={savePart}
          searchLocation={searchLocation}
          searchCompatibility={searchCompatibility}
          makes={makes}
          getModels={getModels}
          setSavePartDetails={setSavePartDetails}
        />
      )}
    </div>
  )

  return content
}

export default QuickSearch

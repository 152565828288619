import React, { useState } from "react"
import Styles from "./SavePartModal.module.scss"

const SavePartModal = ({
  setSaveNewPartModal,
  setSavePartDetails,
  carYear,
  carMake,
  carModel,
  salesPer,
  price,
  title,
  image = ""
}) => {

    console.log(carYear, carMake, carModel, salesPer, price, title)
  const [inputs, setInputs] = useState({
    imageLink: image,
    partTitle: title || "",
    salesPerMonth: salesPer || "",
    averageSalePrice: price || "",
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleNext = () => {
    setSavePartDetails({
      imgurl: imageLink,
      title: partTitle,
      salesPer: salesPerMonth,
      price: averageSalePrice,
      carYear,
      carMake,
      carModel,
    })
    setSaveNewPartModal(null)
    // Implement your next step logic here
  }

  let { imageLink, partTitle, salesPerMonth, averageSalePrice } = inputs

  return (
    <div className={Styles["savePartModalWrapper"]}>
      <div className={Styles["modalContent"]}>
        <input
          type="text"
          name="imageLink"
          placeholder="Paste image link here..."
          value={imageLink}
          onChange={handleInputChange}
          className={Styles["imageInput"]}
        />
        {imageLink && (
          <div className={Styles["imagePreview"]}>
            <img
              src={imageLink}
              alt="Preview"
              className={Styles["previewImage"]}
            />
          </div>
        )}

        <input
          type="text"
          name="partTitle"
          placeholder="Enter part title..."
          value={partTitle}
          onChange={handleInputChange}
          className={Styles["inputField"]}
        />

        <div className={Styles["numberInputs"]}>
          <input
            type="number"
            placeholder="Sales per month"
            name="salesPerMonth"
            value={inputs.salesPerMonth}
            onChange={handleInputChange}
            className={Styles["inputField"]}
          />
          <input
            type="number"
            placeholder="Average sale price"
            name="averageSalePrice"
            value={inputs.averageSalePrice}
            onChange={handleInputChange}
            className={Styles["inputField"]}
          />
        </div>
        <div className={Styles["buttonWrapper"]}>
          <button
            onClick={() => setSaveNewPartModal(null)}
            className={Styles["nextButton"]}
          >
            Cancel
          </button>
          <button
            onClick={handleNext}
            className={Styles["nextButton"]}
            disabled={
              !imageLink || !partTitle || !salesPerMonth || !averageSalePrice
            }
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default SavePartModal

import { useState } from "react"
import Styles from "./SaveNewPart.module.scss"

const SaveNewPart = (props) => {
  console.log(props)
  let {
    savePart,
    title = "",
    carYear = "",
    carMake = "",
    carModel = "",
    salesPer,
    price,
    imgurl,
    makes,
    getModels,
    searchLocation,
    searchCompatibility,
    setSavePartDetails,
  } = props

  const [saveInfo, changeInfo] = useState({
    title: title,
    salesPer: salesPer,
    price: price,
    notes: "",
    imgurl: imgurl,
    compatibleCars: carYear
      ? [
          {
            yearFrom: carYear,
            yearTo: carYear,
            make: carMake,
            model: carModel,
            id: `${carYear}${carYear}${carMake}${carModel}`,
          },
        ]
      : [],
    location: "",
  })

  const [year = "", make = "", model = ""] = [
    saveInfo.compatibleCars[0]?.yearFrom,
    saveInfo.compatibleCars[0]?.make,
    saveInfo.compatibleCars[0]?.model,
  ]

  const [compCarInputs, setCompCarInputs] = useState({
    isManualEntry: false,
    showInputs: false,
    editMode: false,
    yearFrom: "",
    yearTo: "",
    make: "",
    model: "",
    id: "",
  })

  const [models, setModels] = useState(["Enter Manually"])

  const handleDropDownChange = (e) => {
    const { value } = e.target

    if (value === "Enter Manually") {
      setCompCarInputs((prev) => ({
        ...prev,
        isManualEntry: true,
        model: "",
      }))
    } else {
      setCompCarInputs((prev) => ({
        ...prev,
        isManualEntry: false,
        model: value,
      }))
    }
  }
  const handleEditClick = async () => {
    let selector = document.querySelector("#cars")
    const { value } = selector
    if (value) {
      let foundCar = saveInfo.compatibleCars.find((x) => x.id === value)
      let models = await getModels(foundCar.make)
      setModels([...models, "Enter Manually"])
      setCompCarInputs({
        ...foundCar,
        showInputs: true,
        editMode: true,
        isManualEntry: false,
      })
    }
  }
  const handleConfirm = () => {
    const { yearFrom, yearTo, make, model, id } = compCarInputs
    if (compCarInputs.editMode) {
      let foundIndex = saveInfo.compatibleCars.findIndex((car) => car.id === id)
      let arrayToEdit = saveInfo.compatibleCars
      arrayToEdit[foundIndex] = { yearFrom, yearTo, make, model, id }
      changeInfo({ ...saveInfo, compatibleCars: arrayToEdit })
      clearCompInputs()
    } else if (!(yearFrom && yearTo && make && model)) {
      alert("You cannot have any empty fields")
    } else {
      let foundDuplicate = saveInfo.compatibleCars.find(
        (x) =>
          x.yearTo === yearTo &&
          x.yearFrom === yearFrom &&
          x.make === make &&
          x.model === model
      )
      if (!foundDuplicate) {
        changeInfo({
          ...saveInfo,
          compatibleCars: [
            ...saveInfo.compatibleCars,
            {
              yearFrom,
              yearTo,
              make,
              model,
              id: `${yearFrom}${yearTo}${make}${model}`,
            },
          ],
        })
      }
      clearCompInputs()
    }
  }

  const handleCancel = () => {
    clearCompInputs()
  }

  const handleInputChange = (e) => {
    const { value, id } = e.target
    changeInfo({ ...saveInfo, [id]: value })
  }

  const compatibleOptions = () => {
    let options = saveInfo.compatibleCars.map((car, index) => {
      const { yearTo, yearFrom, make, model, id } = car
      const yearRange = yearTo === yearFrom ? year : `${yearFrom}-${yearTo}`
      return (
        <option
          key={id}
          id={id}
          value={id}
        >{`${yearRange} ${make} ${model}`}</option>
      )
    })

    return options
  }

  const handleAddCar = () => {
    setCompCarInputs({
      ...compCarInputs,
      showInputs: true,
      isManualEntry: false,
    })
  }
  const handleDeleteCar = () => {
    let idToDelete = document.querySelector("#cars").value
    let modifiedArray = saveInfo.compatibleCars.filter(
      (car) => car.id !== idToDelete
    )
    changeInfo({
      ...saveInfo,
      compatibleCars: modifiedArray,
    })
    clearCompInputs()
  }

  const handleCompInput = (e) => {
    const { value, id } = e.target
    if (id === "yearFrom" && !compCarInputs.yearTo) {
      setCompCarInputs({ ...compCarInputs, yearFrom: value, yearTo: value })
    } else {
      setCompCarInputs({ ...compCarInputs, [id]: value })
    }
  }
  const handleMakeChange = async (e) => {
    const { value, id } = e.target
    let models = await getModels(value)
    setModels([...models, "Enter Manually"])
    setCompCarInputs({ ...compCarInputs, make: value })
  }

  const handleSave = () => {
    const { compatibleCars, title, salesPer, price } = saveInfo
    if ((compatibleCars.length !== 0 && title, salesPer, price)) {
      savePart(saveInfo)
    } else {
      alert("There must not be any empty fields, including compatible cars.")
    }
  }
  const makeOptions = () => {
    let options = makes.map((make) => {
      return (
        <option key={make} id={make} value={make}>
          {make}
        </option>
      )
    })

    return options
  }

  const modelOptions = () => {
    let options = models.map((model) => {
      return (
        <option key={model} id={model} value={model}>
          {model}
        </option>
      )
    })

    return options
  }

  const yearOptions = () => {
    let numbers = []
    for (let i = new Date().getFullYear(); i > 1964; i--) {
      numbers.push(i)
    }
    let options = numbers.map((number) => {
      return (
        <option key={number} id={number} value={number}>
          {number}
        </option>
      )
    })

    return options
  }

  const showNotesAndLocation = () => {
    return (
      <div className={Styles["locationNoteWrapper"]}>
        <input
          id="location"
          type="text"
          placeholder="Where on the car?"
          value={saveInfo.location}
          onChange={handleInputChange}
        />
        <input
          id="notes"
          type="text"
          placeholder="Leave a note"
          value={saveInfo.notes}
          onChange={handleInputChange}
        />
      </div>
    )
  }

  const showCompInputs = () => {
    return (
      <div className={Styles["addCarInputs"]} style={{ display: "flex" }}>
        <img
          className={Styles["addOrDeleteImg"]}
          onClick={handleCancel}
          src={require("../../../images/cancel.png")}
        />
        <select
          className={Styles["yearDropdown"]}
          onChange={handleCompInput}
          name="yearFrom"
          id="yearFrom"
          value={compCarInputs.yearFrom}
        >
          <option key="yearFrom" value="" disabled>
            Year
          </option>
          {yearOptions()}
        </select>
        <span>-</span>
        <select
          className={Styles["yearDropdown"]}
          onChange={handleCompInput}
          name="yearTo"
          id="yearTo"
          value={compCarInputs.yearTo}
        >
          <option key="yearTo" value="" disabled>
            Year
          </option>
          {yearOptions()}
        </select>
        <select
          className={Styles["dropdown"]}
          onChange={handleMakeChange}
          name="make"
          id="make"
          value={compCarInputs.make}
        >
          <option key="make" value="" disabled>
            Makes
          </option>
          {makeOptions()}
        </select>

        {compCarInputs.isManualEntry ? (
          <input
            className={Styles["dropdown"]}
            type="text"
            id="model"
            value={compCarInputs.model}
            onChange={(e) => handleCompInput(e)}
            placeholder="Model"
            autoFocus
          />
        ) : (
          <select
            className={Styles["dropdown"]}
            onChange={handleDropDownChange}
            name="model"
            id="model"
            value={compCarInputs.model}
          >
            <option key="model" value="" disabled>
              Models
            </option>
            {modelOptions()}
          </select>
        )}

        <div className={Styles["confirmDelete"]}>
          <img
            className={Styles["addOrDeleteImg"]}
            onClick={handleConfirm}
            src={require("../../../images/confirm.png")}
          />
        </div>
      </div>
    )
  }

  function clearCompInputs() {
    setCompCarInputs({
      ...compCarInputs,
      model: "",
      id: "",
      showInputs: false,
      editMode: false,
    })
  }

  return (
    <div className={Styles.outterWrapper}>
      <div className={Styles["innerWrap"]}>
        <i
          className={`${Styles["compSearch"]} material-icons`}
          onClick={() => searchCompatibility(year, make, model, saveInfo.title)}
        >
          directions_car
        </i>
        <input
          id="title"
          type="text"
          value={saveInfo.title}
          onChange={handleInputChange}
        />
        <i
          className={`${Styles["locationSearch"]} material-icons`}
          onClick={() => searchLocation(year, make, model, title)}
        >
          search
        </i>

        <div className={Styles["partNumbers"]}>
          <img
            className={Styles["partsImage"]}
            src={require("../icons/parts.png")}
          />
          <input
            id="salesPer"
            type="number"
            value={saveInfo.salesPer}
            onChange={handleInputChange}
          />

          <img
            className={Styles["partsImage2"]}
            src={require("../icons/money.png")}
          />

          <input
            id="price"
            type="number"
            value={saveInfo.price}
            onChange={handleInputChange}
          />
        </div>
        <div className={Styles["addCarWrapper"]}>
          <i onClick={handleEditClick} className="material-icons">
            edit_note
          </i>
          <select onChange={handleDropDownChange} name="cars" id="cars">
            <option key="compatVehic" value="" disabled>
              Compatible Vehicles
            </option>
            {compatibleOptions()}
          </select>
          <img
            onClick={handleAddCar}
            src={require("../../../images/addCar.png")}
          />
          <img
            onClick={handleDeleteCar}
            src={require("../../../images/deleteCar.png")}
          />
        </div>
        {compCarInputs.showInputs ? showCompInputs() : showNotesAndLocation()}

        <div className={Styles.buttons}>
          <p onClick={() => setSavePartDetails(null)}>Cancel</p>
          <p onClick={handleSave}>Save</p>
        </div>
      </div>
    </div>
  )
}

export default SaveNewPart

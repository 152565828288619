import React, { useState } from "react"
import Styles from "./ListingTable.module.scss"
import buildSavePartDetails from "../SaveNewPart/utils/buildSavePartDetails"

const ListingGroup = ({ setSavePartDetails, group, makes, getModels }) => {
  const [expanded, setExpanded] = useState(false)
  const count = group.length
  const totalPrice = group.reduce((sum, listing) => sum + listing.price, 0)
  const averagePrice = totalPrice / count
  const firstListing = group[0]

  function expandGroup(e) {
    const isImgOrTitle =
      e.target.classList.contains(Styles.listingImage) ||
      e.target.classList.contains(Styles.listingTitle);
  
    // If expanded and the click target is not the header (currentTarget) and not image or title, do nothing.
    if (expanded && e.target !== e.currentTarget && !isImgOrTitle) return;
  
    const headerElement = e.currentTarget;
    const container = headerElement.closest(`.${Styles.listingGroup}`);
    const scrollContainer = document.querySelector(`.${Styles.listingTableWrapper}`);
    const offset = 5; // Adjust the offset as needed
    const willExpand = !expanded; // capture the intended state
  
    // Helper function defined inside expandGroup so it can use the variables from closure
    function scrollToGroup() {
      if (container && scrollContainer) {
        scrollContainer.scrollTo({
          top: container.offsetTop - offset,
          behavior: "smooth",
        });
      }
      // Only scroll the window if we're expanding
      if (container && willExpand) {
        const windowOffset = container.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({
          top: windowOffset,
          behavior: "smooth",
        });
      }
    }
  
    if (willExpand) {
      setExpanded(true);
      setTimeout(scrollToGroup, 100);
    } else {
      setExpanded(false);
      setTimeout(scrollToGroup, 100);
    }
  }
  return (
    <div
      onClick={expandGroup}
      key={`${firstListing.title}${count}`}
      className={`${Styles.listingGroup} ${expanded ? Styles.expanded : ""}`}
    >
      <div className={Styles.listingCount}>
        <strong>{count}</strong>
      </div>
      <img
        src={firstListing.imgurl}
        alt={firstListing.title}
        className={Styles.listingImage}
      />
      <h3 className={Styles.listingTitle}>{firstListing.title}</h3>
      <div className="spacer"></div>
      <p className={Styles.listingPrice}>
        <strong>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(averagePrice)}
        </strong>
      </p>
      {expanded && (
        <div className={Styles.listingGroupItems}>
          {group.map((listing) =>
            buildListingComponent(listing, setSavePartDetails, makes, getModels)
          )}
        </div>
      )}
    </div>
  )
}
export default ListingGroup

const buildListingComponent = (
  listing,
  setSavePartDetails,
  makes,
  getModels
) => {
  return (
    <div
      onDoubleClick={() =>
        handleSavePart(listing, setSavePartDetails, makes, getModels)
      }
      key={`${listing.imgurl}${listing.saleDate}${listing.price}`}
      className={Styles.card}
    >
      <p className={Styles.text}>{listing.condition}</p>
      <img src={listing.imgurl} alt={listing.title} className={Styles.image} />
      <h3 className={Styles.title} data-fulltitle={listing.title}>
        {listing.title}
      </h3>
      <div className="spacer"></div>
      <p className={Styles.text}>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(listing.price)}
      </p>
      <p className={Styles.text}>{listing.saleDate}</p>
    </div>
  )
}

async function handleSavePart(listing, setSavePartDetails, makes, getModels) {
  const savePartDetails = await buildSavePartDetails(listing, makes, getModels)
  setSavePartDetails(savePartDetails)
}

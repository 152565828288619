import React, { useState, useEffect } from "react"
import Styles from "./SellerDisplay.module.scss"

const SellerDisplay = ({ sellerInfo }) => {
  const { storeName, logo, location, itemCount, storeStats, totalSales } = sellerInfo
  let [feedback, sales, followers] = storeStats
  if (feedback) feedback = feedback.replace("positive", "")

  // Use local state to trigger the expansion after initial render,
  // so the transition on max-height can animate from 0 to the expanded value.
  const [expanded, setExpanded] = useState(false)
  
  useEffect(() => {
    if (storeName) {
      // Delay setting expanded to true so that the initial render is rendered at 0 height.
      const timer = setTimeout(() => setExpanded(true), 50)
      return () => clearTimeout(timer)
    } else {
      setExpanded(false)
    }
  }, [storeName])

  const wrapperClass = expanded
    ? `${Styles.sellerDisplayWrapper} ${Styles.show}`
    : Styles.sellerDisplayWrapper

  return (
    <div className={wrapperClass}>
      <div className={Styles.header}>
        {logo && <img src={logo} alt={storeName} className={Styles.logo} />}
        <div className={Styles.storeInfo}>
          <h2 className={Styles.storeName}>{storeName}</h2>
          {location && <p className={Styles.location}>{location}</p>}
        </div>
      </div>
      <div className={Styles.details}>
        <div className={Styles.metric}>
          <span className={Styles.value}>{`${itemCount.toLocaleString()} listings`}</span>
        </div>
        <div className={Styles.metric}>
          <span className={Styles.value}>{feedback}</span>
        </div>
        <div className={Styles.metric}>
          <span className={Styles.value}>{sales}</span>
        </div>
        <div className={Styles.metric}>
          <span className={Styles.value}>{followers}</span>
        </div>
      </div>
      <div className={Styles.totalSales}>
        <span className={Styles.totalLabel}>90 day total</span>
        <span className={Styles.totalValue}>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(totalSales)}
        </span>
      </div>
    </div>
  )
}

export default SellerDisplay
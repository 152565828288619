import React from "react";
import { Link } from "react-router-dom";
import Styles from "./Menu.module.scss";

const Menu = (props) => {

    const { toggleMenu } = props;

    const handleClick = (e) => {
        console.log(e.target.id)
        if (e.target.id != "menuWrapper") {
            toggleMenu(false)
        }
    }

    return (
        <div onClick={handleClick} className={Styles.wrapper}>
            <div className={Styles.menuWrapper} id="menuWrapper">
                <Link to="/research">Research</Link>
                <Link to="/yardsearch">YardSearch</Link>
                <Link to="/sellersearch">SellerSearch</Link>
                <Link to="/hotlist">Hot List</Link>
                <Link to="/priceList">Price List</Link>
                <Link to="/auth/signin">Logout</Link>
            </div>
        </div>
    );
}

export default Menu;
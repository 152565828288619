import yardNames from "./yardNameEnums";
import getDays from "./getDays";
import createPullTicketList from "./createPullTicketList"
const { OGDEN_PIC_A_PART, OGDEN_TEAR_A_PART, OREM_PIC_A_PART, SLC_PIC_N_PULL, SLC_PULL_N_SAVE, SLC_TEAR_A_PART, SV_PULL_N_SAVE } = yardNames;

const createAutoResearchList = (cars, hotList, autoSearchDays, pullTickets, filters ) => {
    console.log(autoSearchDays)
    filters = {
        newestCars: true,
        hotList: true
    }
    const yardList = parseCarList(cars, hotList, filters, autoSearchDays);
    const autoResearchList = Object.keys(yardList).map(x => ({ [x]: buildYardRowList(yardList[x]) }));
    const pullTicketList = createPullTicketList(autoResearchList, pullTickets)

    return {autoResearchList, pullTicketList}
}

function parseCarList(cars, hotList, filters, autoSearchDays) {
    const hotListOn = filters.hotList;
    const newestCarsOn = filters.newestCars;
    const rowsEliminated = [3001]
    const carListObject = {
        ogdenPAP: [],
        ogdenTAP: [],
        oremPAP: [],
        slcPNP: [],
        slcPNS: [],
        slcTAP: [],
        svTAP: []
    }
    //filter for cars that have been in the yard for 7 days or less with parts,  and/or is on the hotlist
    const areNewestCars = (cars) => {
        return cars.filter(car => getDays(car.date) <= autoSearchDays )
    }

    const newestCars = newestCarsOn && areNewestCars(cars);
    const hotListCars = hotListOn && filterHotListCars(cars, hotList);

    const allCars = mergeCarLists(newestCars, hotListCars);

    const filteredCars = allCars.filter(car => rowsEliminated.indexOf(car.row) === -1)

    //right here is where you decide if you will get all parts or not. Merge that array by first checking each new car
    // to see if it exists in the array. Use forEach(), if it exists modify, if not, push.
    //use findIndex() put a callback inside (x) => x.vin === vin
    const carList = filteredCars.reduce((listOfYards, x) => {

        const yard = x.yard;
        switch (yard) {
            case OGDEN_PIC_A_PART:
                listOfYards.ogdenPAP.push(x);
                break;
            case OGDEN_TEAR_A_PART:
                listOfYards.ogdenTAP.push(x);
                break;
            case OREM_PIC_A_PART:
                listOfYards.oremPAP.push(x);
                break;
            case SLC_PIC_N_PULL:
                listOfYards.slcPNP.push(x);
                break;
            case SLC_PULL_N_SAVE:
                listOfYards.slcPNS.push(x);
                break;
            case SLC_TEAR_A_PART:
                listOfYards.slcTAP.push(x);
                break;
            case SV_PULL_N_SAVE:
                listOfYards.svTAP.push(x);
                break;
            default:
        }

        return listOfYards;
    }, { ...carListObject });

    return carList;
}

function buildYardRowList(cars) {
    const yardListObject = {}

    cars.forEach(x => {
        const { notes = "" } = x;

        const carDetails = {
            ...x,
            notes: notes
        }
        yardListObject[x.row] = yardListObject[x.row] ? [...yardListObject[x.row], carDetails] : [carDetails];

    })

    return yardListObject;
}

function getHotListItem(car, hotList) {
    const { year, make, model } = car
    return hotList.find(x => (year >= x.yearFrom &&
        year <= x.yearTo &&
        make.toLowerCase() === x.make.toLowerCase() &&
        model.toLowerCase() === x.model.toLowerCase()));
}

function filterHotListCars(cars, hotList) {
    return cars.filter((car, index) => {
        const hotListItem = getHotListItem(car, hotList);
        const isHot = car.days <= 30 && hotListItem;
        if (isHot) {
            cars[index].notes = hotListItem.part || ""
            return true;
        }
        return false;
    })
}
//NEED TO RE WRITE THIS. ITS CUTE BUT TAKES TOO LONG
function mergeCarLists(...carList) {
    const allCars = []
    carList.forEach(carList => {
        carList.forEach(car => {
            const callBack = (allCar) => allCar.vin === car.vin
            const existingIndex = allCars.findIndex(callBack);
            if (existingIndex !== -1) {
                allCars[existingIndex] = { ...allCars[existingIndex], ...car };
            } else {
                allCars.push(car)
            }
        })

    })
    return allCars;
}

export default createAutoResearchList;
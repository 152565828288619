import toProperCase from "../../../../helpers/toProperCase"
String.prototype.toProperCase = toProperCase

async function extractCompatibleVehicles(makes, getModels, vehicleDetails) {
  const { _yearRanges: yearRange, _makeModel: makesAndModels } = vehicleDetails
  const yearFrom = yearRange[0]
  const yearTo = yearRange[yearRange.length - 1]
  const compatibleVehicles = []
  const makesModels = {}

  // Using a for...of loop to allow awaiting getModels
  for (const makeOrModel of makesAndModels) {
    if (
      makes.some((item) => item.toLowerCase() === makeOrModel.toLowerCase())
    ) {
      const models = await getModels(makeOrModel)
      const modelsFound = models.filter((model) =>
        makesAndModels.some(
          (item) => item.toLowerCase() === model.toLowerCase()
        )
      )
      makesModels[makeOrModel.toProperCase()] = modelsFound
    }
  }

  for (const make in makesModels) {
    const models = makesModels[make]
    for (const model of models) {
      const vehicle = {
        yearFrom,
        yearTo,
        make,
        model,
        id: `${yearFrom}${yearTo}${make}${model}`,
      }
      compatibleVehicles.push(vehicle)
    }
  }
// console.log(compatibleVehicles)
  return compatibleVehicles
}

export default extractCompatibleVehicles

// let example = {
//     yearFrom: carYear,
//     yearTo: carYear,
//     make: carMake,
//     model: carModel,
//     id: `${yearFrom}${yearTo}${carMake}${carModel}`,
//   }

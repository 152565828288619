import React, { useState } from "react"
import Styles from "./SearchModal.module.scss"

const SearchModal = ({ getSellerInfo, loading, applyFilters, filters }) => {
  const initialStoreName = filters?.username || ""
  const [inputs, setInputs] = useState(
    filters || {
      minPrice: 1,
      maxPrice: 1000,
      minQuantity: 1,
      saleAgeLimit: 90,
      sortBy: "countThenPrice",
      keywordsToIgnore: "",
      keyword: "",
      username: "",
    }
  )

  const handleLoadSeller = (e) => {
    e.preventDefault()
    console.log(initialStoreName)
    if (inputs.username && inputs.username !== initialStoreName) {
      applyFilters({ ...inputs, username: inputs.username })
      getSellerInfo(inputs.username)
    }
  }

  const handleApplyFilters = (e) => {
    e.preventDefault()
    applyFilters(inputs)
  }

  const handleUsernameChange = (e) => {
    setInputs((prev) => {
      return { ...prev, username: e.target.value }
    })
  }

  const handleSliderChange = (field) => (e) => {
    const newValue = Number(e.target.value)
    const newFilters = { ...inputs }

    if (field === "minPrice") {
      newFilters.minPrice = newValue
      if (newValue > newFilters.maxPrice) {
        newFilters.maxPrice = newValue
      }
    } else if (field === "maxPrice") {
      newFilters.maxPrice = newValue
      if (newValue < newFilters.minPrice) {
        newFilters.minPrice = newValue
      }
    } else {
      newFilters[field] = newValue
    }

    setInputs(newFilters)
  }

  const handleKeywordChange = (e) => {
    setInputs((prev) => {
      return { ...prev, keyword: e.target.value }
    })
  }

  const handleKeywordsToIgnoreChange = (e) => {
    setInputs((prev) => {
      return { ...prev, keywordsToIgnore: e.target.value }
    })
  }

  return (
    <div className={Styles.modalContainer}>
      <form className={Styles.searchModal}>
        {/* Username and Load Button */}
        <div className={Styles.topRow}>
          <input
            type="text"
            placeholder="Enter username"
            value={inputs.username}
            onChange={handleUsernameChange}
            className={Styles.usernameInput}
          />
          <button
            onClick={handleLoadSeller}
            disabled={inputs.username === initialStoreName && loading }
            className={Styles.loadButton}
          >
            {inputs.username === initialStoreName && loading ? "Loading..." : "Load Seller"}
          </button>
        </div>

        {/* Slider Inputs */}
        <div className={Styles.slidersContainer}>
          <div className={Styles.sliderGroup}>
            <label htmlFor="minPrice">Min Price: {inputs.minPrice}</label>
            <input
              id="minPrice"
              type="range"
              min="1"
              max="1000"
              value={inputs.minPrice}
              onChange={handleSliderChange("minPrice")}
            />
          </div>
          <div className={Styles.sliderGroup}>
            <label htmlFor="maxPrice">Max Price: {inputs.maxPrice}</label>
            <input
              id="maxPrice"
              type="range"
              min="2"
              max="1000"
              value={inputs.maxPrice}
              onChange={handleSliderChange("maxPrice")}
            />
          </div>
          <div className={Styles.sliderGroup}>
            <label htmlFor="minQuantity">
              Min Quantity: {inputs.minQuantity}
            </label>
            <input
              id="minQuantity"
              type="range"
              min="1"
              max="20"
              value={inputs.minQuantity}
              onChange={handleSliderChange("minQuantity")}
            />
          </div>
          <div className={Styles.sliderGroup}>
            <label htmlFor="saleAgeLimit">
              Sale Age (days): {inputs.saleAgeLimit}
            </label>
            <input
              id="saleAgeLimit"
              type="range"
              min="0"
              max="90"
              value={inputs.saleAgeLimit}
              onChange={handleSliderChange("saleAgeLimit")}
            />
          </div>
        </div>

        {/* Text Inputs for Keyword and Keywords to Ignore */}
        <div className={Styles.textInputsContainer}>
          <div className={Styles.inputGroup}>
            <label htmlFor="keyword">Keyword</label>
            <input
              id="keyword"
              type="text"
              placeholder="Enter keyword"
              value={inputs.keyword}
              onChange={handleKeywordChange}
            />
          </div>
          <div className={Styles.inputGroup}>
            <label htmlFor="keywordsToIgnore">
              Keywords to Ignore (comma-separated)
            </label>
            <input
              id="keywordsToIgnore"
              type="text"
              placeholder="e.g., headlight, hood"
              value={inputs.keywordsToIgnore}
              onChange={handleKeywordsToIgnoreChange}
            />
          </div>
        </div>
        <button
          onClick={handleApplyFilters}
          disabled={loading}
          className={Styles.filtersButton}
        >
          Apply Filters
        </button>
      </form>
    </div>
  )
}

export default SearchModal

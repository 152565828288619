import React, { useState, useEffect } from "react"
import { Card, CardContent, CardActions } from "@mui/material"
import Button from "@mui/material/Button"
import Slider from "@mui/material/Slider"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { TextField } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#b64e29",
      label: "white",
    },
    secondary: {
      main: "#d32f2f",
    },
  },
})

const SearchModal = ({ getAllResearchParts }) => {
  // Initialize filters from localStorage or use defaults
  const [filters, setFilters] = useState(() => {
    const savedFilters = JSON.parse(localStorage.getItem("filters"))
    return (
      savedFilters || {
        minPrice: 40,
        maxPrice: 1000,
        minQuantity: 6,
        sortBy: "car",
        keyword: "",
        keywordsToIgnore: ""
      }
    )
  })

  const [loading, setLoading] = useState(false)

  // Update a specific filter property
  const updateFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  // Ensure minPrice is always <= maxPrice
  const handleMinPriceChange = (value) => {
    updateFilter("minPrice", value)
    if (value > filters.maxPrice) {
      updateFilter("maxPrice", value)
    }
  }

  // Ensure maxPrice is always >= minPrice
  const handleMaxPriceChange = (value) => {
    updateFilter("maxPrice", value)
    if (value < filters.minPrice) {
      updateFilter("minPrice", value)
    }
  }

  const handleSearch = () => {
    // Save filters to localStorage
    localStorage.setItem("filters", JSON.stringify(filters))
    // Call the search function
    getAllResearchParts(filters.minPrice, filters.maxPrice, filters.minQuantity, filters.sortBy, filters.keyword, filters.keywordsToIgnore)
    setLoading(true)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Card
          sx={{
            width: 400,
            padding: 4,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            maxHeight: "90%",
            overflow: "auto",
          }}
        >
          <CardContent>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="body2" color="white">
                Sort By
              </Typography>
              <Select
                value={filters.sortBy}
                onChange={(e) => updateFilter("sortBy", e.target.value)}
                fullWidth
                sx={{
                  color: "white",
                  "& .MuiSelect-icon": { color: "white" },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.23)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "rgb(0,0,0,1)",
                      zIndex: 1400,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <MenuItem value="price" sx={{color: "white"}}>Sort by Price</MenuItem>
                <MenuItem value="quantity" sx={{color: "white"}}>Sort by Quantity</MenuItem>
                <MenuItem value="car" sx={{color: "white"}}>Sort by Car</MenuItem>
              </Select>
            </Box>

            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="body2" color="primary.label" padding="8px">
                Keyword
              </Typography>
              <TextField
                value={filters.keyword}
                onChange={(e) => updateFilter("keyword", e.target.value.toUpperCase())}
                fullWidth
                variant="outlined"
                placeholder="Enter keyword"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.25)",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.main",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                    "&::placeholder": {
                      color: "white",
                    },
                  },
                }}
              />
              <Typography variant="body2" color="primary.label" padding="8px">
                Keywords to Ignore
              </Typography>
              <TextField
                value={filters.keywordsToIgnore}
                onChange={(e) => updateFilter("keywordsToIgnore", e.target.value.toUpperCase())}
                fullWidth
                variant="outlined"
                placeholder="Separate by space"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.25)",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.main",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                    "&::placeholder": {
                      color: "white",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="body2" color="primary.label">
                Minimum Price
              </Typography>
              <Slider
                value={filters.minPrice}
                min={40}
                max={600}
                step={10}
                onChange={(e, value) => handleMinPriceChange(value)}
                valueLabelDisplay="auto"
              />
              <Typography align="right" variant="body2" color="primary.label">
                ${filters.minPrice}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="body2" color="primary.label">
                Maximum Price
              </Typography>
              <Slider
                value={filters.maxPrice}
                min={50}
                max={1000}
                step={10}
                onChange={(e, value) => handleMaxPriceChange(value)}
                valueLabelDisplay="auto"
              />
              <Typography align="right" variant="body2" color="primary.label">
                ${filters.maxPrice}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="body2" color="primary.label">
                Minimum Quantity
              </Typography>
              <Slider
                value={filters.minQuantity}
                min={3}
                max={50}
                step={1}
                onChange={(e, value) => updateFilter("minQuantity", value)}
                valueLabelDisplay="auto"
              />
              <Typography align="right" variant="body2" color="primary.label">
                {filters.minQuantity}
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" fullWidth onClick={handleSearch}>
              {loading ? "Searching..." : "Search"}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </ThemeProvider>
  )
}

export default SearchModal

import React from "react"
import Styles from "./SaveNewPart.module.scss"

const CompatibileCars = ({ handleDropDownChange, saveInfo, changeInfo, year, getModels, setModels, setCompCarInputs, compCarInputs, clearCompInputs}) => {

  const compatibleOptions = () => {
    let options = saveInfo.compatibleCars.map((car, index) => {
      const { yearTo, yearFrom, make, model, id } = car
      const yearRange = yearTo === yearFrom ? year : `${yearFrom}-${yearTo}`
      return (
        <option
          key={id}
          id={id}
          value={id}
        >{`${yearRange} ${make} ${model}`}</option>
      )
    })

    return options
  }

  const handleEditClick = async () => {
    let selector = document.querySelector("#cars")
    const { value } = selector
    if (value) {
      let foundCar = saveInfo.compatibleCars.find((x) => x.id === value)
      let models = await getModels(foundCar.make)
      setModels([...models, "Enter Manually"])
      setCompCarInputs({
        ...foundCar,
        showInputs: true,
        editMode: true,
        isManualEntry: false,
      })
    }
  }

  const handleAddCar = () => {
    setCompCarInputs({
      ...compCarInputs,
      showInputs: true,
      isManualEntry: false,
    })
  }
  const handleDeleteCar = () => {
    let idToDelete = document.querySelector("#cars").value
    let modifiedArray = saveInfo.compatibleCars.filter(
      (car) => car.id !== idToDelete
    )
    changeInfo({
      ...saveInfo,
      compatibleCars: modifiedArray,
    })
    clearCompInputs()
  }
  return (
    <div className={Styles["addCarWrapper"]}>
      <i onClick={handleEditClick} className="material-icons">
        edit_note
      </i>
      <select onChange={handleDropDownChange} name="cars" id="cars">
        <option key="compatVehic" value="" disabled>
          Compatible Vehicles
        </option>
        {compatibleOptions()}
      </select>
      <img onClick={handleAddCar} src={require("../../../images/addCar.png")} />
      <img
        onClick={handleDeleteCar}
        src={require("../../../images/deleteCar.png")}
      />
    </div>
  )
}

export default CompatibileCars

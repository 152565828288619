import { useState } from "react"
import Styles from "./SaveNewPart.module.scss"
import CompatibileCars from "./CompatibleCars"
import CompatibilityInput from "./CompatibiltyInput"

const SaveNewPart = (props) => {
  console.log(props)
  let {
    savePart,
    makes,
    getModels,
    searchLocation,
    searchCompatibility,
    setSavePartDetails,
    savePartDetails,
  } = props
  const { compatibleCars, notes, title, salesPer, price, imgurl } =
    savePartDetails || {}

  const [saveInfo, changeInfo] = useState({
    title,
    salesPer,
    price,
    notes,
    imgurl,
    compatibleCars,
    location: "",
  })

  const [year = "", make = "", model = ""] = [
    saveInfo.compatibleCars[0]?.yearFrom,
    saveInfo.compatibleCars[0]?.make,
    saveInfo.compatibleCars[0]?.model,
  ]

  const [compCarInputs, setCompCarInputs] = useState({
    isManualEntry: false,
    showInputs: false,
    editMode: false,
    yearFrom: year,
    yearTo: year,
    make: make,
    model: "",
    id: "",
  })

  function clearCompInputs() {
    setCompCarInputs({
      ...compCarInputs,
      model: "",
      id: "",
      showInputs: false,
      editMode: false,
    })
  }
  const [models, setModels] = useState(["Enter Manually"])

  const handleDropDownChange = (e) => {
    const { value } = e.target

    if (value === "Enter Manually") {
      setCompCarInputs((prev) => ({
        ...prev,
        isManualEntry: true,
        model: "",
      }))
    } else {
      setCompCarInputs((prev) => ({
        ...prev,
        isManualEntry: false,
        model: value,
      }))
    }
  }

  const handleInputChange = (e) => {
    const { value, id } = e.target
    changeInfo({ ...saveInfo, [id]: value })
  }

  const handleSave = () => {
    const { compatibleCars, title, salesPer, price } = saveInfo
    if ((compatibleCars.length !== 0 && title, salesPer, price)) {
      savePart(saveInfo)
    } else {
      alert("There must not be any empty fields, including compatible cars.")
    }
  }

  const showNotesAndLocation = () => {
    return (
      <div className={Styles["locationNoteWrapper"]}>
        <input
          id="location"
          type="text"
          placeholder="Where on the car?"
          value={saveInfo.location}
          onChange={handleInputChange}
        />
        <input
          id="notes"
          type="text"
          placeholder="Leave a note"
          value={saveInfo.notes}
          onChange={handleInputChange}
        />
      </div>
    )
  }

  return (
    <div className={Styles.outterWrapper}>
      <div className={Styles["innerWrap"]}>
        <i
          className={`${Styles["compSearch"]} material-icons`}
          onClick={() => searchCompatibility(year, make, model, saveInfo.title)}
        >
          directions_car
        </i>
        <input
          id="title"
          type="text"
          value={saveInfo.title}
          onChange={handleInputChange}
        />
        <i
          className={`${Styles["locationSearch"]} material-icons`}
          onClick={() => searchLocation(year, make, model, title)}
        >
          search
        </i>

        <div className={Styles["partNumbers"]}>
          <img
            className={Styles["partsImage"]}
            src={require("./icons/parts.png")}
          />
          <input
            id="salesPer"
            type="number"
            value={saveInfo.salesPer}
            onChange={handleInputChange}
          />

          <img
            className={Styles["partsImage2"]}
            src={require("./icons/money.png")}
          />

          <input
            id="price"
            type="number"
            value={saveInfo.price}
            onChange={handleInputChange}
          />
        </div>
        <CompatibileCars
          {...{
            handleDropDownChange,
            saveInfo,
            changeInfo,
            year,
            getModels,
            models,
            setModels,
            setCompCarInputs,
            compCarInputs,
            clearCompInputs,
          }}
        />
        {compCarInputs.showInputs ? (
          <CompatibilityInput
            {...{
              clearCompInputs,
              handleDropDownChange,
              compCarInputs,
              setCompCarInputs,
              saveInfo,
              changeInfo,
              models,
              setModels,
              getModels,
              makes,
            }}
          />
        ) : (
          showNotesAndLocation()
        )}

        <div className={Styles.buttons}>
          <p onClick={() => setSavePartDetails(null)}>Cancel</p>
          <p onClick={handleSave}>Save</p>
        </div>
      </div>
    </div>
  )
}

export default SaveNewPart

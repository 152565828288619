export function applyFiltersToListings(listings, filters) {
  const {
    minPrice,
    maxPrice,
    minQuantity,
    saleAgeLimit,
    keywordsToIgnore,
    keyword,
    sortBy,
    // username is ignored per requirements
  } = filters

  const now = new Date()

  // For each group of listings, filter the individual listings.
  const filteredListings = listings
    .map((group) =>
      group.filter((listing) => {
        // Filter out listings if the price is out of range.
        if (listing.price < minPrice || listing.price > maxPrice) return false

        // Filter out listings older than saleAgeLimit (in days).
        // Assuming listing.saleDate is in a format parseable by Date (e.g. "MM/DD/YYYY").
        const saleDate = new Date(listing.saleDate)
        const diffDays = (now - saleDate) / (1000 * 60 * 60 * 24)
        if (diffDays > saleAgeLimit) return false

        // If a keyword is specified, ensure the listing title includes it.
        if (
          keyword &&
          !listing.title.toLowerCase().includes(keyword.toLowerCase())
        )
          return false

        // Exclude listings that contain any keyword in keywordsToIgnore.
        if (
          keywordsToIgnore && keywordsToIgnore.split(",").some((ignore) =>
            listing.title.toLowerCase().includes(ignore.toLowerCase().trim())
          )
        )
          return false

        return true
      })
    )
    // Only keep groups with at least minQuantity listings.
    .filter((group) => group.length >= minQuantity)

  // Sorting is handled separately via sortListings.
  const sortedListings = sortListings(filteredListings, sortBy)
  return sortedListings
}

export function sortListings(listingGroups, sortBy) {
  console.log(sortBy)
  switch (sortBy) {
    case "count":
      // Sort by the number of items in each group (largest first)
      return listingGroups.sort((a, b) => b.length - a.length)

    case "countThenPrice":
      // First sort by count.
      // If two groups have the same count, sort by their average listing price (highest first)
      return listingGroups.sort((a, b) => {
        const countDiff = b.length - a.length
        if (countDiff !== 0) return countDiff

        const avgA =
          a.reduce((sum, listing) => sum + listing.price, 0) / a.length
        const avgB =
          b.reduce((sum, listing) => sum + listing.price, 0) / b.length
        return avgB - avgA
      })

    case "averagePrice":
      // Sort by the average price of the listings in each group (highest first)
      return listingGroups.sort((a, b) => {
        const avgA =
          a.reduce((sum, listing) => sum + listing.price, 0) / a.length
        const avgB =
          b.reduce((sum, listing) => sum + listing.price, 0) / b.length
        return avgB - avgA
      })

    case "totalPrice":
      // Sort by the total price of the listings in each group (highest first)
      return listingGroups.sort((a, b) => {
        const totalA = a.reduce((sum, listing) => sum + listing.price, 0)
        const totalB = b.reduce((sum, listing) => sum + listing.price, 0)
        return totalB - totalA
      })

    default:
      // Default: return the groups without sorting if unknown sortBy
      return listingGroups
  }
}
